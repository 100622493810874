import { Dispatch, useEffect } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import type React from "react";
import { useImmerReducer } from "use-immer";
import Message from "../atoms/Message";

interface InputPopUpMessageData {
  text: string;
  type: "success" | "danger" | "warning";
}

interface PopUpMessageData extends InputPopUpMessageData {
  id: string;
}

interface PopUpMessageContextState {
  popUpMessage: PopUpMessageData[];
}

export const addPopUpMessage = (payload: InputPopUpMessageData) => ({
  type: "addPopUpMessage" as const,
  payload,
});

export const delPopUpMessage = (payload: string) => ({
  type: "delPopUpMessage" as const,
  payload,
});

type PopUpMessageContextAction =
  | ReturnType<typeof addPopUpMessage>
  | ReturnType<typeof delPopUpMessage>;

const initialState: PopUpMessageContextState = {
  popUpMessage: [],
};

const PopUpMessageContext =
  createContext<PopUpMessageContextState>(initialState);

const PopUpMessageDispatchContext = createContext<
  Dispatch<PopUpMessageContextAction> | undefined
>(undefined);

export function usePopUpMessage() {
  const popUpMessages = useContextSelector(
    PopUpMessageContext,
    (state) => state.popUpMessage,
  );
  return popUpMessages;
}

export function usePopUpMessageDispatch() {
  const context = useContextSelector(
    PopUpMessageDispatchContext,
    (state) => state,
  );
  if (context === undefined) {
    throw new Error();
  }
  return context;
}

const reducer = (
  draft: PopUpMessageContextState,
  action: PopUpMessageContextAction,
) => {
  switch (action.type) {
    case "addPopUpMessage": {
      draft.popUpMessage.push({ ...action.payload, id: crypto.randomUUID() });
      break;
    }
    case "delPopUpMessage": {
      draft.popUpMessage = draft.popUpMessage.filter(
        (m) => m.id !== action.payload,
      );
      break;
    }
    default:
      console.log("not pattern");
      break;
  }
};

export default function PopUpMessageProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, dispatch] = useImmerReducer(reducer, initialState);

  return (
    <PopUpMessageContext.Provider value={state}>
      <PopUpMessageDispatchContext.Provider value={dispatch}>
        {children}
        <PopUpMessage />
      </PopUpMessageDispatchContext.Provider>
    </PopUpMessageContext.Provider>
  );
}

function PopUpMessage() {
  const alert = usePopUpMessage();
  const dispatch = usePopUpMessageDispatch();

  useEffect(() => {
    setTimeout(() => {
      alert.length > 0 && dispatch(delPopUpMessage(alert[0].id));
    }, 5000);
  }, [alert, dispatch]);

  return alert.map((a, i) => (
    <div
      key={i}
      className={`fixed z-10 bottom-4 left-4 w-[300px] animate-slide-in-left`}
    >
      <Message text={a.text} type={a.type} />
    </div>
  ));
}
