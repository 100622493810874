import { useMemo } from "react";

interface MessageProps {
  label?: string;
  text: string;
  type: "secondary" | "info" | "success" | "danger" | "warning";
}

export default function Message({ label, text, type }: MessageProps) {
  const style = useMemo(() => {
    switch (type) {
      case "secondary":
        return "mt-2 bg-gray-50 border border-gray-200 text-sm text-gray-600 rounded-lg p-4 dark:bg-white/10 dark:border-white/10 dark:text-neutral-400";
      case "info":
        return "mt-2 bg-blue-100 border border-blue-200 text-sm text-blue-800 rounded-lg p-4 dark:bg-blue-800/10 dark:border-blue-900 dark:text-blue-500";
      case "success":
        return "mt-2 bg-teal-100 border border-teal-200 text-sm text-teal-800 rounded-lg p-4 dark:bg-teal-800/10 dark:border-teal-900 dark:text-teal-500";
      case "danger":
        return "mt-2 bg-red-100 border border-red-200 text-sm text-red-800 rounded-lg p-4 dark:bg-red-800/10 dark:border-red-900 dark:text-red-500";
      case "warning":
        return "mt-2 bg-yellow-100 border border-yellow-200 text-sm text-yellow-800 rounded-lg p-4 dark:bg-yellow-800/10 dark:border-yellow-900 dark:text-yellow-500";
    }
  }, [type]);

  return (
    <div className={style} role="alert">
      <span className="font-bold">{label}</span> {text}
    </div>
  );
}
